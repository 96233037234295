import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { routePaths } from 'global/routePaths'
import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { toast } from 'react-toastify'

const editFmAndTvs = ({ ...postData }) => {
  if (postData.id) {
    const updatedEndpoints = endpoints.fmandtvs.base
    return http('multipart').put(updatedEndpoints, postData)
  }
}

export function useEditFmAndTvs() {
  const history = useHistory()

  return useMutation(editFmAndTvs, {
    onSuccess: () => {
      toast.success('Fm And Tvs has been edited successfully')
      history.push(routePaths.home.fmandtvs.base)
    },
    onError: (err) => {
      toast.error('Unfortunately, update of Fm And Tvs was not successful.')
    },
  })
}
