import { useMutation } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { toast } from 'react-toastify'

const editAdvertisement = ({ ...postData }) => {
  if (postData.id) {
    const updatedEndpoints = endpoints.advertisement.base
    return http('multipart').put(updatedEndpoints, postData)
  }
}

export function useEditAdvertisement() {
  return useMutation(editAdvertisement, {})
}
