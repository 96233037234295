/* eslint-disable no-unused-vars */
import {
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTableDataCell,
  CPagination,
  CPaginationItem,
  CImage,
  CContainer,
  CRow,
  CCol,
  CFormInput,
  CInputGroup,
  CInputGroupText,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilPlus, cilMagnifyingGlass } from '@coreui/icons'
import { BsFillTrashFill } from 'react-icons/bs'
import { BsPencilSquare } from 'react-icons/bs'
import React from 'react'
import { PropTypes } from 'prop-types'

import SwitchCheckBox from 'components/SwitchCheckBox/SwitchCheckBox'
import FormatDate from 'utils/format'

const Table = (props) => {
  const {
    payload,
    columns,
    handleAdd,
    handleEdit,
    handleStatus,
    handleFeatured,
    handleBreaking,
    handleDelete,
    handleSearch,
    handleDetail,
    handlePopular,
    paginationData,
    handleChangePage,
  } = props
  // TODO: Add a column called type there.
  return (
    <>
      <CContainer className="overflow-hidden">
        <CRow className="d-flex justify-content-end align-items-center flex-row py-2 ps-0 mt-3">
          <CCol sm={7} md={6} className="d-flex flex-row align-items-center justify-content-end">
            {handleSearch && (
              <CInputGroup className={`input-prepend ${handleAdd && 'px-2'}`}>
                <CFormInput type="text" placeholder="Search" onChange={handleSearch} />
                <CInputGroupText>
                  <CIcon icon={cilMagnifyingGlass} />
                </CInputGroupText>
              </CInputGroup>
            )}
            {handleAdd && (
              <button
                type="button"
                className="d-flex ml-2 btn btn-outline-secondary justify-content-between align-items-center"
                onClick={() => handleAdd()}
              >
                Add{'      '}
                <CIcon icon={cilPlus} size="sm" />
              </button>
            )}
          </CCol>
        </CRow>
        <CRow>
          <CTable hover responsive bordered>
            <CTableHead color="dark">
              <CTableRow>
                {Array.isArray(columns) &&
                  columns.length > 0 &&
                  columns.map((column, index) => (
                    <CTableHeaderCell scope="col" key={index}>
                      {column.name}
                    </CTableHeaderCell>
                  ))}
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {Array.isArray(payload) && payload.length > 0 ? (
                payload.map((data, idx) => (
                  <CTableRow key={idx}>
                    {columns.map((col, index) => {
                      if (col.key === 'id') {
                        return (
                          <CTableDataCell key={index} onClick={() => handleDetail(data.id)}>
                            {paginationData
                              ? (paginationData?.current_page - 1) * paginationData?.per_page +
                                idx +
                                1
                              : idx + 1}
                          </CTableDataCell>
                        )
                      } else if (col.key === 'channel_icon' || col.key === 'imageUrl') {
                        return (
                          <CTableDataCell key={index}>
                            {data.channel_icon || data.imageUrl ? (
                              <CImage
                                rounded
                                thumbnail
                                width={200}
                                height={200}
                                src={data.channel_icon || data.imageUrl}
                              />
                            ) : (
                              'No image to preview'
                            )}
                          </CTableDataCell>
                        )
                      } else if (col.key === 'image') {
                        return (
                          <CTableDataCell key={index} onClick={() => handleDetail(data.id)}>
                            {(data.image && data.image.imageUrl) || data.image ? (
                              <CImage
                                rounded
                                thumbnail
                                width={250}
                                height={250}
                                src={data.image.imageUrl || data.image}
                              />
                            ) : (
                              'No image to preview'
                            )}
                          </CTableDataCell>
                        )
                      } else if (col.key === 'total_days') {
                        return (
                          <CTableDataCell key={index} onClick={() => handleDetail(data.id)}>
                            {Math.ceil(
                              Math.abs(
                                new Date(data?.image?.created_at) -
                                  new Date(data?.image?.updated_at),
                              ) /
                                (1000 * 60 * 60 * 24),
                            )}
                          </CTableDataCell>
                        )
                      } else if (col.key === 'created_at' || col.key === 'updated_at') {
                        return (
                          <CTableDataCell key={index} onClick={() => handleDetail(data.id)}>
                            {FormatDate(data[col.key])}
                          </CTableDataCell>
                        )
                      } else if (col.key === 'action') {
                        return (
                          <CTableDataCell key={index}>
                            {handleDelete && (
                              <BsFillTrashFill onClick={() => handleDelete(data.id)} />
                            )}
                            {handleEdit && (
                              <BsPencilSquare
                                onClick={() => handleEdit(data.id)}
                                className="mx-1"
                              />
                            )}
                          </CTableDataCell>
                        )
                      } else if (col.key === 'link' || col.key === 'live_stream_link') {
                        return (
                          <CTableDataCell key={index}>
                            <a href={data?.link || data?.live_stream_link} className="text-break">
                              {data?.link && (data?.link ? data?.link : 'N/A')}
                              {data?.live_stream_link &&
                                (data?.live_stream_link ? data?.live_stream_link : 'N/A')}
                            </a>
                            {data?.live_stream_link || data?.link ? '' : 'N/A'}
                          </CTableDataCell>
                        )
                      } else if (col.key === 'status') {
                        return (
                          <CTableDataCell key={index}>
                            <SwitchCheckBox
                              handleSwitch={handleStatus}
                              id={data.id}
                              value={data.status === 1 ? true : false}
                            />
                          </CTableDataCell>
                        )
                      } else if (col.key === 'is_featured') {
                        return (
                          <CTableDataCell key={index}>
                            <SwitchCheckBox
                              handleSwitch={handleFeatured}
                              id={data.id}
                              value={data.is_featured === 1 ? true : false}
                            />
                          </CTableDataCell>
                        )
                      } else if (col.key === 'is_breaking_news') {
                        return (
                          <CTableDataCell key={index}>
                            <SwitchCheckBox
                              handleSwitch={handleBreaking}
                              id={data.id}
                              value={data.is_breaking_news === 1 ? true : false}
                            />
                          </CTableDataCell>
                        )
                      } else if (col.key === 'is_popular') {
                        return (
                          <CTableDataCell key={index}>
                            <SwitchCheckBox
                              handleSwitch={handlePopular}
                              id={data.id}
                              value={data.is_popular === 1 ? true : false}
                            />
                          </CTableDataCell>
                        )
                      }
                      return (
                        <CTableDataCell
                          key={index}
                          onClick={() => handleDetail(data.id)}
                          className="text-break"
                        >
                          {data[col.key] || 'n/a'}
                        </CTableDataCell>
                      )
                    })}
                  </CTableRow>
                ))
              ) : (
                <CTableRow>
                  <CTableDataCell colSpan={columns.length} className="text-center">
                    No data Found!
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
          {paginationData && (
            <CPagination align="end" aria-label="Page navigation example">
              <CPaginationItem
                disabled={paginationData?.last_page === 1}
                onClick={() => handleChangePage(paginationData?.current_page - 1)}
              >
                Previous
              </CPaginationItem>
              {Array(paginationData?.last_page)
                .fill()
                .map((number, index) => (
                  <CPaginationItem key={index} onClick={() => handleChangePage(index)}>
                    {index + 1}
                  </CPaginationItem>
                ))}
              <CPaginationItem
                disabled={paginationData?.last_page === paginationData?.current_page}
                onClick={() => handleChangePage(paginationData?.current_page + 1)}
              >
                Next
              </CPaginationItem>
            </CPagination>
          )}
        </CRow>
      </CContainer>
    </>
  )
}

export default Table

Table.propTypes = {
  toggle: PropTypes.bool,
  payload: PropTypes.array,
  columns: PropTypes.array,
  handleAdd: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleStatus: PropTypes.func,
  handleFeatured: PropTypes.func,
  handleBreaking: PropTypes.func,
  handleSearch: PropTypes.func,
  handleDetail: PropTypes.func,
  handlePopular: PropTypes.func,
  handleChangePage: PropTypes.func,
  paginationData: PropTypes.object,
}
