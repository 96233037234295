import React from 'react'
import Spinner from 'components/spinner/Spinner'
import { useFetchCategoryById } from 'hooks/category/useFetchCategoryById'
import { useParams } from 'react-router-dom'
import { CCol, CContainer, CFormLabel, CRow, CImage } from '@coreui/react'
import FormatDate from 'utils/format'

function CategoryDetails() {
  const { id } = useParams()
  const { isLoading, data: category } = useFetchCategoryById(id, { enabled: !!id })

  if (isLoading) {
    return <Spinner />
  }

  return (
    <CContainer className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
      <CRow>
        <CCol sm={12} md={12}>
          <CImage
            rounded
            thumbnail
            width={150}
            height={150}
            src={category?.payload?.image?.imageUrl}
            className="mb-3"
          />
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Title:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{category.payload?.title ? category.payload?.title : 'N/A'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Nepalese Title:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{category.payload?.title_np ? category.payload?.title_np : 'N/A'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Sub Title:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{category.payload?.sub_title ? category.payload?.sub_title : 'N/A'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Code:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{category.payload?.code ? category.payload?.code : 'N/A'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Status:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{category.payload?.status ? 'Active' : 'Inactive'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Is Popular:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{category.payload?.is_popular ? 'True' : 'False'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Is Priority:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{category.payload?.priority ? 'True' : 'False'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Created at:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{category.payload?.created_at ? FormatDate(category.payload?.created_at) : 'N/A'}</p>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default CategoryDetails
