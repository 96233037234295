import { endpoints } from 'global/endpoints'
import { useQuery } from 'react-query'
import http from 'utils/http'

const fetchEventsById = (id) => {
  const updatedEndpoints = endpoints.events.eventsById.replace('{id}', id)
  return http().get(updatedEndpoints)
}

export function useFetchEventsById(id, options) {
  return useQuery(['fetchEventsById', id], () => fetchEventsById(id), options)
}
