import React from 'react'

import { CNavItem, CNavTitle } from '@coreui/react'
import CIcon from '@coreui/icons-react'
// React icons
import {
  cilAudioDescription,
  cilBalanceScale,
  cilNewspaper,
  cilLocationPin,
  cilUser,
  cilGrid,
  cilVideo,
  cilScreenDesktop,
  cilCalendar,
  cilBook,
} from '@coreui/icons'

const constants = {
  navbar: [
    {
      component: CNavTitle,
      name: 'Edit Options',
    },
    {
      component: CNavItem,
      name: 'Advertisements',
      to: '/advertisement',
      icon: <CIcon icon={cilAudioDescription} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'User',
      to: '/user',
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Gold and Silver',
      to: '/gold&silver',
      icon: <CIcon icon={cilBalanceScale} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Category',
      to: '/category',
      icon: <CIcon icon={cilGrid} customClassName="nav-icon" />,
    },
    // {
    //   component: CNavItem,
    //   name: 'Location',
    //   to: '/location',
    //   icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
    // },
    {
      component: CNavItem,
      name: 'News',
      to: '/news',
      icon: <CIcon icon={cilNewspaper} customClassName="nav-icon" />,
    },
    // {
    //   component: CNavItem,
    //   name: 'Videos',
    //   to: '/videos',
    //   icon: <CIcon icon={cilVideo} customClassName="nav-icon" />,
    // },
    {
      component: CNavItem,
      name: 'Fm And Tvs',
      to: '/fm&tvs',
      icon: <CIcon icon={cilScreenDesktop} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Events',
      to: '/events',
      icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Epaper',
      to: '/epaper',
      icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
    },
  ],
}

export default constants

export const sortingOrder = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
}
