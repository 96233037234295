import { QueryClient } from 'react-query'
import { toast } from 'react-toastify'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
      onError: (errorData) => {
        console.log(errorData)
      },
    },
    mutations: {
      onSettled: (data) => {
        if (data) {
          console.log(data)
        }
      },
    },
  },
})
