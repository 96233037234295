import React from 'react'
import Spinner from 'components/spinner/Spinner'
import { useFetchUserById } from 'hooks/user/useFetchUserById'
import { useParams } from 'react-router-dom'
import { CCol, CContainer, CFormLabel, CRow } from '@coreui/react'

function UserDetail() {
  const { id } = useParams()
  const { isLoading, data: user } = useFetchUserById(id, { enabled: !!id })

  if (isLoading) {
    return <Spinner />
  }

  return (
    <CContainer className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
      <CRow>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">First Name:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{user.payload?.first_name}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Last Name:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{user.payload?.last_name}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">UserName:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{user.payload?.username}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Email:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{user.payload?.email}</p>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default UserDetail
