import React from 'react'
import AddAdvertisement from './views/pages/advertisement/AddAdvertisement'
import EditAdvertisement from './views/pages/advertisement/EditAdvertisement'
import AdvertisementDetails from './views/pages/advertisement/AdvertisementDetails'
import AddUser from './views/pages/user/AddUser'
import EditUser from './views/pages/user/EditUser'
import UserDetail from './views/pages/user/UserDetail'
import NewsDetails from './views/pages/News/NewsDetails'
import EditGoldAndSilver from './views/pages/GoldAndSilver/EditGoldAndSilver'
import GoldAndSilverDetails from './views/pages/GoldAndSilver/GoldAndSilverDetails'
import AddCategory from './views/pages/Category/AddCategory'
import EditCategory from './views/pages/Category/EditCategory'
import CategoryDetails from './views/pages/Category/CategoryDetails'
import AddFmAndTvs from './views/pages/FmAndTvs/AddFmAndTvs'
import EditFmAndTvs from './views/pages/FmAndTvs/EditFmAndTvs'
import FmAndTvsDetails from './views/pages/FmAndTvs/FmAndTvsDetails'
import AddEvents from './views/pages/Events/AddEvents'
import EditEvents from './views/pages/Events/EditEvents'
import EventsDetails from './views/pages/Events/EventsDetails'
import AddEpaper from './views/pages/Epaper/AddEpaper'
import EditEpaper from './views/pages/Epaper/EditEpaper'
import EpaperDetails from './views/pages/Epaper/EpaperDetails'

const Advertisement = React.lazy(() => import('./views/pages/advertisement'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const User = React.lazy(() => import('./views/pages/user'))
const GoldAndSilver = React.lazy(() => import('./views/pages/GoldAndSilver'))
const Category = React.lazy(() => import('./views/pages/Category'))
const Location = React.lazy(() => import('./views/pages/Location'))
const News = React.lazy(() => import('./views/pages/News'))
const Videos = React.lazy(() => import('./views/pages/Videos'))
const FmAndTvs = React.lazy(() => import('./views/pages/FmAndTvs'))
const Events = React.lazy(() => import('./views/pages/Events'))
const Epaper = React.lazy(() => import('./views/pages/Epaper'))

const routes = [
  { path: '/', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/advertisement', name: 'Advertisement', component: Advertisement },
  { path: '/advertisement/add', name: 'Add Advertisement', component: AddAdvertisement },
  { path: '/advertisement/edit/:id', name: 'Advertisement Details', component: EditAdvertisement },
  { path: '/advertisement/:id', name: 'Advertisement Details', component: AdvertisementDetails },
  { path: '/user', name: 'User', component: User },
  { path: '/user/add', name: 'Add User', component: AddUser },
  { path: '/user/edit/:id', name: 'Edit User', component: EditUser },
  { path: '/user/:id', name: 'User Detail', component: UserDetail },
  { path: '/gold&silver', name: 'Gold And Silver', component: GoldAndSilver },
  { path: '/gold&silver/edit/:id', name: 'Edit Gold And Silver', component: EditGoldAndSilver },
  { path: '/gold&silver/:id', name: 'Gold And Silver Details', component: GoldAndSilverDetails },
  { path: '/category', name: 'Category', component: Category },
  { path: '/category/add', name: 'Add Category', component: AddCategory },
  { path: '/category/edit/:id', name: 'Edit Category', component: EditCategory },
  { path: '/category/:id', name: 'Category Details', component: CategoryDetails },
  { path: '/location', name: 'Location', component: Location },
  { path: '/news', name: 'News', component: News },
  { path: '/news/:id', name: 'News Details', component: NewsDetails },
  { path: '/videos', name: 'Videos', component: Videos },
  { path: '/fm&tvs', name: 'Fm And Tvs', component: FmAndTvs },
  { path: '/fm&tvs/add', name: 'Add Fm And Tvs', component: AddFmAndTvs },
  { path: '/fm&tvs/edit/:id', name: 'Edit Fm And Tvs', component: EditFmAndTvs },
  { path: '/fm&tvs/:id', name: 'Fm And Tvs Details', component: FmAndTvsDetails },
  { path: '/events', name: 'Events', component: Events },
  { path: '/events/add', name: 'Add Events', component: AddEvents },
  { path: '/events/edit/:id', name: 'Edit Events', component: EditEvents },
  { path: '/events/:id', name: 'Events Details', component: EventsDetails },
  { path: '/epaper', name: 'Epaper', component: Epaper },
  { path: '/epaper/add', name: 'Add Epaper', component: AddEpaper },
  { path: '/epaper/edit/:id', name: 'Edit Epaper', component: EditEpaper },
  { path: '/epaper/:id', name: 'Epaper Details', component: EpaperDetails },
]

export default routes

