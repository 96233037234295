import React, { useState } from 'react'
import { usePostAdvertisement } from 'hooks/advertisement/usePostAdvertisement'
import { useGetAdvertisementTypes } from 'hooks/advertisement/useGetAdvertisementTypes'
import { CCol, CButton, CImage } from '@coreui/react'
import FormikControl from 'components/FormikControl'
import image from 'assets/images/add.png'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

const AddAdvertisement = () => {
  const [initialValues, setInitialValues] = useState({
    code: '',
    title: '',
    type_id: 0,
    image: '',
    redirect_link: '',
  })

  const { mutate: addAdvertisement } = usePostAdvertisement()
  const { data: types } = useGetAdvertisementTypes()

  const handleFormSubmit = (data) => {
    console.log(data)
    let bodyFormData = new FormData()
    bodyFormData.append('code', data.code)
    bodyFormData.append('title', data.title)
    bodyFormData.append('type_id', data.type_id)
    bodyFormData.append('image', data.image)
    bodyFormData.append('redirect_link', data.redirect_link)
    addAdvertisement(bodyFormData)
  }

  const validationSchema = Yup.object({
    code: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
    image: Yup.string().required('Required'),
    redirect_link: Yup.string().required('Required'),
  })

  // TODO: Store the advertisement errors into a state, then render the appropriate error message on the applicable input fields. The prop documentation of coreUI is given here: https://coreui.io/react/docs/4.0/forms/validation/. Follow it.
  return (
    <div>
      <div className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            handleFormSubmit(values)
          }}
        >
          {({ setFieldValue }) => (
            <Form autoComplete="off" className="row g-3">
              <CCol md={12} style={{ position: 'relative' }}>
                <CImage
                  rounded
                  thumbnail
                  width={120}
                  height={120}
                  src={(initialValues.image && URL.createObjectURL(initialValues.image)) || image}
                />
                <input
                  type="file"
                  placeholder="Image"
                  label="Image"
                  name="image"
                  style={{
                    opacity: 0,
                    left: 0,
                    position: 'absolute',
                    height: 120,
                    width: 120,
                    zIndex: 9999,
                  }}
                  onChange={(event) => {
                    setInitialValues({ ...initialValues, image: event.currentTarget.files[0] })
                    setFieldValue('image', event.currentTarget.files[0])
                  }}
                />
              </CCol>
              <FormikControl
                control="input"
                type="text"
                placeholder="Code of Advertisement"
                label="Code of Advertisement"
                name="code"
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Title of Advertisement"
                label="Title of Advertisement"
                name="title"
              />
              <FormikControl
                control="select"
                placeholder="Type of Advertisement"
                type="text"
                label="Type of Advertisement"
                name="type"
                // options={types.payload}
              />
              <FormikControl
                control="input"
                placeholder="Redirect link"
                type="text"
                label="Redirect link"
                name="redirect_link"
              />
              <CCol xs={12}>
                <CButton type="submit" className="mt-2">
                  Add Advertisement
                </CButton>
              </CCol>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AddAdvertisement
