import { endpoints } from 'global/endpoints'
import { useQuery } from 'react-query'
import http from 'utils/http'

const fetchNewsById = (id) => {
  const updatedEndpoints = endpoints.news.newsById.replace('{id}', id)
  return http().get(updatedEndpoints)
}

export function useFetchNewsById(id, options) {
  return useQuery(['fetchNewsById', id], () => fetchNewsById(id), options)
}
