import { endpoints } from 'global/endpoints'
import { useQuery } from 'react-query'
import http from 'utils/http'

const getAdvertisementById = (id) => {
  const updatedEndpoints = endpoints.advertisement.specific.replace('{id}', id)
  return http().get(updatedEndpoints)
}

export function useGetAdvertisementById(id, options) {
  return useQuery(['getAdvertisementById', id], () => getAdvertisementById(id), options)
}
