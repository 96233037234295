import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { sortingOrder } from 'global/constants'

import Table from 'components/Table'
import { useFetchUser } from 'hooks/user/useFetchUser'
import { useDeleteUser } from 'hooks/user/useDeleteUser'
import Spinner from 'components/spinner/Spinner'
import { routePaths } from 'global/routePaths'

const User = () => {
  const history = useHistory()
  const [filterSchema, setFilterSchema] = useState({
    page: 1,
    page_size: 15,
    search: '',
    orderBy: 'id',
    order: sortingOrder.ASCENDING,
  })
  const { isLoading, data: user } = useFetchUser(filterSchema)
  const { mutate: deleteUser } = useDeleteUser()

  const columns = [
    { key: 'id', name: 'SN' },
    { key: 'first_name', name: 'First Name' },
    { key: 'last_name', name: 'Last Name' },
    { key: 'username', name: 'Username' },
    { key: 'provider', name: 'Provider' },
    { key: 'created_at', name: 'Created Date' },
    { key: 'action', name: 'Action' },
  ]

  const handleSearch = (e) => {
    const updatedFilterSchema = {
      ...filterSchema,
      search: e.target.value,
    }
    setFilterSchema(updatedFilterSchema)
  }

  const handleAdd = () => {
    history.push(routePaths.home.user.add)
  }

  const handleEdit = (id) => {
    history.push(routePaths.home.user.edit.replace('{id}', id))
  }

  const handleDelete = (id) => {
    deleteUser(id)
  }

  const handleDetail = (id) => {
    history.push(routePaths.home.user.detail.replace('{id}', id))
  }

  const handleStatus = (e, status) => {
    console.log(e.taget)
  }

  const handleChangePage = (newPage) => {
    const updatedFilterSchema = {
      ...filterSchema,
      page: ++newPage,
      page_size: filterSchema?.page_size,
    }
    setFilterSchema(updatedFilterSchema)
  }

  if (isLoading && !filterSchema.search) {
    return <Spinner />
  }

  return (
    <div>
      <div className="body flex-grow-1 px-3">
        <Table
          payload={user?.payload}
          columns={columns}
          handleSearch={handleSearch}
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleStatus={handleStatus}
          handleDetail={handleDetail}
          paginationData={user?.meta_data?.pagination}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  )
}

export default User
