import React, { useState } from 'react'
import { useUpdateCategory } from 'hooks/category/useUpdateCategory'
import { useFetchCategoryById } from 'hooks/category/useFetchCategoryById'
import FormikControl from 'components/FormikControl'
import { CCol, CButton, CImage } from '@coreui/react'
import Spinner from 'components/spinner/Spinner'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import image from 'assets/images/add.png'
import * as Yup from 'yup'

const EditCategory = () => {
  const { id } = useParams()
  const [initialValues, setInitialValues] = useState({
    id: 0,
    code: '',
    title: '',
    image_id: '',
    priority: '',
  })

  const { mutate: updateCategory } = useUpdateCategory()

  const onSuccess = (data) => {
    setInitialValues({
      ...initialValues,
      id: data?.payload.id,
      code: data.payload.code,
      title: data.payload.title,
      image_id: data?.payload?.image?.imageUrl,
      priority: data.payload.priority,
    })
  }

  const { isLoading } = useFetchCategoryById(id, { enabled: !!id, onSuccess })

  const handleFormSubmit = (data) => {
    let bodyFormData = new FormData()
    bodyFormData.append('id', data.id)
    bodyFormData.append('code', data.code)
    bodyFormData.append('title', data.title)
    bodyFormData.append('image_id', data.image_id)
    bodyFormData.append('priority', data.priority)
    updateCategory(bodyFormData)
  }

  const validationSchema = Yup.object({
    code: Yup.string(),
    title: Yup.string(),
    image_id: Yup.string().required('Required'),
    priority: Yup.string().required('Required'),
  })

  if (isLoading) {
    return <Spinner />
  }

  // TODO: Store the advertisement errors into a state, then render the appropriate error message on the applicable input fields. The prop documentation of coreUI is given here: https://coreui.io/react/docs/4.0/forms/validation/. Follow it.
  return (
    <div>
      <div className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            handleFormSubmit(values)
          }}
        >
          {({ setFieldValue }) => (
            <Form autoComplete="off" className="row g-3">
              <CCol md={12} style={{ position: 'relative' }}>
                <CImage
                  rounded
                  thumbnail
                  width={120}
                  height={120}
                  src={(initialValues.image_id && initialValues.image_id) || image}
                />
                <input
                  type="file"
                  placeholder="Image"
                  label="Image"
                  name="image_id"
                  style={{
                    opacity: 0,
                    left: 0,
                    position: 'absolute',
                    height: 120,
                    width: 120,
                    zIndex: 9999,
                  }}
                  onChange={(event) => {
                    setInitialValues({
                      ...initialValues,
                      image_id: URL.createObjectURL(event.currentTarget.files[0]),
                    })
                    setFieldValue('image_id', event.currentTarget.files[0])
                  }}
                />
              </CCol>
              <FormikControl
                control="input"
                type="text"
                placeholder="Code"
                label="Code"
                name="code"
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Title"
                label="Title"
                name="title"
              />
              <FormikControl
                control="select"
                placeholder="Priority"
                type="number"
                label="Priority"
                name="priority"
                options={[
                  { key: 'Most', value: 1 },
                  { key: 'Least', value: 0 },
                ]}
              />
              <CCol xs={12}>
                <CButton type="submit" className="mt-2">
                  Update Category
                </CButton>
              </CCol>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default EditCategory
