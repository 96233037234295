import React from 'react'
import Spinner from 'components/spinner/Spinner'
import { useFetchNewsById } from 'hooks/news/useFetchNewsById'
import { useParams } from 'react-router-dom'
import { CCol, CContainer, CFormLabel, CRow, CImage } from '@coreui/react'
import FormatDate from 'utils/format'

function NewsDetail() {
  const { id } = useParams()
  const { isLoading, data: news } = useFetchNewsById(id, { enabled: !!id })

  if (isLoading) {
    return <Spinner />
  }

  return (
    <CContainer className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
      <CRow>
        <CCol sm={12} md={12}>
          <CImage
            rounded
            thumbnail
            width={120}
            height={120}
            src={news.payload?.channel_icon}
            className="mb-3"
          />
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Channel Name:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{news.payload?.channel_name}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Channel Link:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{news.payload?.channel_link}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Title:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{news.payload?.title}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Description:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{news.payload?.description}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">News Tags:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>
            {news.payload?.news_tags.map((tags, index) => (
              <span className="pr-3" key={index}>
                {tags}
              </span>
            ))}
          </p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Status:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{news.payload?.status ? 'Active' : 'Inactive'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Is Breaking News:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{news.payload?.is_breaking_news ? 'True' : 'False'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">News Language Type:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{news.payload?.news_language_type}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Created at:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{FormatDate(news.payload?.created_at)}</p>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default NewsDetail
