import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { PropTypes } from 'prop-types'

import { routePaths } from 'global/routePaths'
import tokenService from 'utils/token'

const PublicRoute = ({ component: Component, exact, path, ...rest }) => {
  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={(props) => {
        if (!tokenService.getAccessToken()) {
          return <Component {...props} />
        }

        return <Redirect to={routePaths.home.base} />
      }}
    />
  )
}

PublicRoute.propTypes = {
  component: PropTypes.elementType,
  exact: PropTypes.bool,
  path: PropTypes.string,
  props: PropTypes.object,
}

export default PublicRoute
