import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import http from 'utils/http'
import { endpoints } from 'global/endpoints'
import { toast } from 'react-toastify'
import { routePaths } from 'global/routePaths'

const createEpaper = (postData) => {
  const updatedEndpoints = endpoints.epaper.base
  return http('multipart').post(updatedEndpoints, postData)
}

export function useCreateEpaper() {
  const history = useHistory()

  return useMutation(createEpaper, {
    onSuccess: () => {
      toast.success('Epaper has been added successfully!')
      history.push(routePaths.home.user.base)
    },
    onError: (error) => {
      const message = error.response.data.message
      toast.error(message)
    },
  })
}
