export const endpoints = {
  login: {
    base: 'v1/admin/login',
  },
  logout: {
    base: 'v1/admin/logout',
  },
  advertisement: {
    base: 'v1/advertisements',
    specific: 'v1/advertisements/{id}',
    types: 'v1/advertisements/types',
  },
  user: {
    base: 'v1/users',
    userById: 'v1/users/{id}',
  },
  goldorsilver: {
    base: 'v1/gold-or-silvers',
    goldorsilverById: 'v1/gold-or-silvers/{id}',
    graph: 'v1/gold-or-silvers/graph/list',
    latest: 'v1/gold-or-silvers/latest-list',
  },
  category: {
    base: 'v1/categories',
    categoryById: 'v1/categories/{id}',
    categoryPopular: 'v1/categories/{id}/popular',
    categoryStatus: 'v1/categories/{id}/change-status',
  },
  location: {
    base: 'v1/locations',
    locationById: 'v1/locations/{id}',
  },
  news: {
    base: 'v1/news',
    newsById: 'v1/news/{id}',
    changeStatus: 'v1/news/{id}/change-status',
  },
  videos: {
    base: 'v1/videos',
    videosById: 'v1/videos/{id}',
    types: 'v1/videos/{id}/types',
  },
  fmandtvs: {
    base: 'v1/fmandtvs',
    fmandtvsById: 'v1/fmandtvs/{id}',
  },
  events: {
    base: 'v1/dobs',
    eventsById: 'v1/dobs/{id}',
  },
  epaper: {
    base: 'v1/magazines',
    epaperById: 'v1/magazines/{id}',
    update: 'v1/magazines/{id}/update',
  },
}
