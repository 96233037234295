import { endpoints } from 'global/endpoints'
import { useQuery } from 'react-query'
import http from 'utils/http'

const fetchCategoryById = (id) => {
  const updatedEndpoints = endpoints.category.categoryById.replace('{id}', id)
  return http().get(updatedEndpoints)
}

export function useFetchCategoryById(id, options) {
  return useQuery(['fetchCategoryById', id], () => fetchCategoryById(id), options)
}
