import React from 'react'
import Spinner from 'components/spinner/Spinner'
import { useFetchGoldAndSilverById } from 'hooks/goldandsilver/useFetchGoldAndSilverById'
import { useParams } from 'react-router-dom'
import { CCol, CContainer, CFormLabel, CRow } from '@coreui/react'
import FormatDate from 'utils/format'

function GoldAndSilverDetails() {
  const { id } = useParams()
  const { isLoading, data: goldAndSilver } = useFetchGoldAndSilverById(id, { enabled: !!id })

  if (isLoading) {
    return <Spinner />
  }

  return (
    <CContainer className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
      <CRow>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Name:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>
            {goldAndSilver.payload?.gold_silver_name
              ? goldAndSilver.payload?.gold_silver_name
              : 'N/A'}
          </p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Type:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>
            {goldAndSilver.payload?.gold_silver_type
              ? goldAndSilver.payload?.gold_silver_type
              : 'N/A'}
          </p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Rate:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{goldAndSilver.payload?.rate ? goldAndSilver.payload?.rate : 'N/A'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Date:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{goldAndSilver.payload?.date ? goldAndSilver.payload?.date : 'N/A'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Created At:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>
            {goldAndSilver.payload?.created_at
              ? FormatDate(goldAndSilver.payload?.created_at)
              : 'N/A'}
          </p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Status:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center text-break">
          <p>
            {goldAndSilver.payload?.status
              ? goldAndSilver.payload?.status
                ? 'True'
                : 'False'
              : 'N/A'}
          </p>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default GoldAndSilverDetails
