import React, { lazy } from 'react'
import { createBrowserHistory } from 'history'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import routes from '../routes'
import PublicRoute from 'components/routes/PublicRoute'
import PrivateRoute from 'components/routes/PrivateRoute'
import { routePaths } from 'global/routePaths'
import { Layout } from 'containers/Layout'

const Page404 = lazy(() => import('views/pages/page404/Page404'))
const Login = lazy(() => import('views/pages/login/Login'))

const history = createBrowserHistory()

const AppRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <PublicRoute exact path={routePaths.auth.login} component={Login} />
        <Layout>
          <Switch>
            {routes.map((route, index) => (
              <PrivateRoute exact key={index} path={route.path} component={route.component} />
            ))}
            <Route component={Page404} />
          </Switch>
        </Layout>
      </Switch>
    </Router>
  )
}

export default AppRouter
