import { endpoints } from 'global/endpoints'
import { useQuery } from 'react-query'
import http from 'utils/http'

const fetchGoldAndSilverById = (id) => {
  const updatedEndpoints = endpoints.goldorsilver.goldorsilverById.replace('{id}', id)
  return http().get(updatedEndpoints)
}

export function useFetchGoldAndSilverById(id, options) {
  return useQuery(['fetchGoldAndSilverById', id], () => fetchGoldAndSilverById(id), options)
}
