import { endpoints } from 'global/endpoints'
import { useQuery } from 'react-query'
import http from 'utils/http'

const getAdvertisementTypes = () => {
  const updatedEndpoints = endpoints.advertisement.types
  return http().get(updatedEndpoints)
}

export function useGetAdvertisementTypes() {
  return useQuery(['getAdvertisementTypes'], () => getAdvertisementTypes())
}
