import React from 'react'
import Spinner from 'components/spinner/Spinner'
import { useGetAdvertisementById } from 'hooks/advertisement/useGetAdvertisementById'
import { useParams } from 'react-router-dom'
import { CCol, CContainer, CFormLabel, CRow, CImage } from '@coreui/react'
import FormatDate from 'utils/format'

function AdvertisementDetails() {
  const { id } = useParams()
  const { isLoading, data: advertisement } = useGetAdvertisementById(id, { enabled: !!id })

  if (isLoading) {
    return <Spinner />
  }

  return (
    <CContainer className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
      <CRow>
        <CCol sm={12} md={12}>
          <CImage
            rounded
            thumbnail
            width={120}
            height={120}
            src={advertisement?.payload?.imageUrl}
            className="mb-3"
          />
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Title:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{advertisement?.payload?.title ? advertisement?.payload?.title : 'N/A'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Nepalese Title:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{advertisement?.payload?.title_np ? advertisement?.payload?.title_np : 'N/A'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Type:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{advertisement?.payload?.type ? advertisement?.payload?.type : 'N/A'}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Link:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <a href={advertisement?.payload?.link}>
            {advertisement?.payload?.link ? advertisement?.payload?.link : 'N/A'}
          </a>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Created At:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>
            {advertisement?.payload?.created_at
              ? FormatDate(advertisement?.payload?.created_at)
              : 'N/A'}
          </p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Status:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{advertisement?.payload && advertisement?.payload?.status ? 'True' : 'False'}</p>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default AdvertisementDetails
