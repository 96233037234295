const { format, parseISO } = require('date-fns')

export default function FormatDate(date) {
  if (date) {
    const date_formatted = parseISO(date, 'MM-dd-yy', new Date())
    var today = format(date_formatted, 'MMM  dd, yyyy')
  } else {
    return 'N/A'
  }
  return today
}
