import * as storage from './storage'

const ACCESS_TOKEN = 'access_token'
const USER_UNIQUE_TOKEN = 'user_unique_token'
const EXPIRY_DATE = 'expiry_date'

const token = () => {
  function _setToken(token) {
    storage.set_local(ACCESS_TOKEN, token)
  }

  function _setExpiryDate(expiryDate) {
    storage.set_local(EXPIRY_DATE, expiryDate)
  }

  function _getAccessToken() {
    return storage.get_local(ACCESS_TOKEN)
  }

  function _getUserUniqueToken() {
    return storage.get_local(USER_UNIQUE_TOKEN)
  }

  function _getTokenExpiryDate() {
    return storage.get_local(EXPIRY_DATE)
  }

  function _clearToken() {
    storage.remove_local(ACCESS_TOKEN)
    storage.remove_local(USER_UNIQUE_TOKEN)
  }

  return {
    setToken: _setToken,
    setExpiryDate: _setExpiryDate,
    getAccessToken: _getAccessToken,
    getUserUniqueToken: _getUserUniqueToken,
    getTokenExpiryDate: _getTokenExpiryDate,
    clearToken: _clearToken,
  }
}

export default token()
