import { PropTypes } from 'prop-types'
import React, { lazy } from 'react'
const Input = lazy(() => import('./Input'))
const Radio = lazy(() => import('./Radio'))
const Checkbox = lazy(() => import('./Checkbox'))
const Select = lazy(() => import('./Select'))
const TextArea = lazy(() => import('./TextArea'))
const DatePicker = lazy(() => import('./DatePicker'))

const FormikControl = ({ control, ...rest }) => {
  switch (control) {
    case 'input':
      return <Input {...rest} />
    case 'textarea':
      return <TextArea {...rest} />
    case 'select':
      return <Select {...rest} />
    case 'datepicker':
      return <DatePicker {...rest} />
    case 'checkbox':
      return <Checkbox {...rest} />
    case 'radio':
      return <Radio {...rest} />
    default:
      return null
  }
}

export default FormikControl

FormikControl.propTypes = {
  control: PropTypes.string,
}
