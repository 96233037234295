import React from 'react'
import { useCreateUser } from 'hooks/user/useCreateUser'
import { CCol, CButton } from '@coreui/react'
import FormikControl from 'components/FormikControl'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

const AddUser = () => {
  const initialValues = {
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
  }

  const { mutate: addUser } = useCreateUser()

  const handleFormSubmit = (data) => {
    console.log(data)
    let bodyFormData = new FormData()
    bodyFormData.append('first_name', data.first_name)
    bodyFormData.append('last_name', data.last_name)
    bodyFormData.append('username', data.username)
    bodyFormData.append('email', data.email)
    bodyFormData.append('password', data.password)
    bodyFormData.append('password_confirmation', data.password_confirmation)
    addUser(bodyFormData)
  }

  const validationSchema = Yup.object({
    first_name: Yup.string(),
    last_name: Yup.string(),
    username: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    password: Yup.string().required('Required').min(8, 'Password must be at leat 6 characters.'),
    password_confirmation: Yup.string()
      .required('Required')
      .min(8, 'Password must be at leat 6 characters.')
      .oneOf([Yup.ref('password'), null], 'The password confirmation does not match.'),
  })

  // TODO: Store the advertisement errors into a state, then render the appropriate error message on the applicable input fields. The prop documentation of coreUI is given here: https://coreui.io/react/docs/4.0/forms/validation/. Follow it.
  return (
    <div>
      <div className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            handleFormSubmit(values)
          }}
        >
          <Form autoComplete="off" className="row g-3">
            <FormikControl
              control="input"
              type="text"
              placeholder="First Name"
              label="First Name"
              name="first_name"
            />
            <FormikControl
              control="input"
              type="text"
              placeholder="Last Name"
              label="Last Name"
              name="last_name"
            />
            <FormikControl
              control="input"
              type="text"
              placeholder="Username"
              label="Username"
              name="username"
            />
            <FormikControl
              control="input"
              placeholder="Email"
              type="email"
              label="Email"
              name="email"
            />
            <FormikControl
              control="input"
              placeholder="Password"
              type="password"
              label="Password"
              name="password"
            />
            <FormikControl
              control="input"
              type="password"
              label="Confirm Password"
              placeholder="Confirm Password"
              name="password_confirmation"
            />
            <CCol xs={12}>
              <CButton type="submit" className="mt-2">
                Add User
              </CButton>
            </CCol>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default AddUser
