import React, { lazy } from 'react'

import User from 'views/pages/user'
import AddAdvertisement from '../views/pages/advertisement/AddAdvertisement/AddAdvertisement'

const Dashboard = lazy(() => import('views/dashboard/Dashboard'))
const Advertisement = lazy(() => import('views/pages/advertisement/Advertisement'))

export const routePaths = {
  // public routes
  auth: {
    forgotPassword: '/forgot-password',
    login: '/login',
    resetPassword: '/reset-password',
    resetPasswordSuccess: '/reset-password-success',
  },

  home: {
    base: '/',
    advertisement: {
      base: '/advertisement',
      add: '/advertisement/add',
      edit: '/advertisement/edit/{id}',
      detail: '/advertisement/{id}',
    },
    user: {
      base: '/user',
      add: '/user/add',
      edit: '/user/edit/{id}',
      detail: '/user/{id}',
    },
    news: {
      base: '/news',
      detail: '/news/{id}',
    },
    goldSilver: {
      base: '/gold&silver',
      edit: '/gold&silver/edit/{id}',
      detail: '/gold&silver/{id}',
    },
    category: {
      base: '/category',
      add: '/category/add',
      edit: '/category/edit/{id}',
      detail: '/category/{id}',
    },
    fmandtvs: {
      base: '/fm&tvs',
      add: '/fm&tvs/add',
      edit: '/fm&tvs/edit/{id}',
      detail: '/fm&tvs/{id}',
    },
    events: {
      base: '/events',
      add: '/events/add',
      edit: '/events/edit/{id}',
      detail: '/events/{id}',
    },
    epaper: {
      base: '/epaper',
      add: '/epaper/add',
      edit: '/epaper/edit/{id}',
      detail: '/epaper/{id}',
    },

    location: '/location',
    videos: '/videos',
  },
}
