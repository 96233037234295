import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { routePaths } from 'global/routePaths'
import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { toast } from 'react-toastify'

const editUser = ({ ...postData }) => {
  if (postData.id) {
    const updatedEndpoints = endpoints.user.base
    return http('multipart').put(updatedEndpoints, postData)
  }
}

export function useEditUser() {
  const history = useHistory()

  return useMutation(editUser, {
    onSuccess: () => {
      toast.success('User has been edited successfully')
      history.push(routePaths.home.user.base)
    },
    onError: (err) => {
      console.log(err)
      toast.error('Unfortunately, update of user was not successful.')
    },
  })
}
