import { useQuery } from 'react-query'
import { stringifyQuery } from 'utils/helpers'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'

export const fetchUser = (searchQuery) => {
  const stringifiedQuery = stringifyQuery(searchQuery)
  const newEndpoints = endpoints.user.base + '?' + stringifiedQuery
  return http().get(newEndpoints)
}

export function useFetchUser(searchQuery, options) {
  return useQuery(['fetchUser', searchQuery], () => fetchUser(searchQuery), options)
}
