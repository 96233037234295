import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { routePaths } from 'global/routePaths'
import tokenService from 'utils/token'
import { PropTypes } from 'prop-types'

/**
 * If the user is authenticated then the user cannot access routes related to authentication.
 * If the use is not authenticated then users cannot access routesother than the login (authentication) routes
 * Store the Bearer tokens in the session storage for added security
 */

const PrivateRoute = ({ component: Component, exact, path }) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        if (!tokenService.getAccessToken()) {
          return <Redirect to={routePaths.auth.login} />
        }
        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  exact: PropTypes.bool,
  path: PropTypes.string,
}
