import React, { useState } from 'react'
import { useEditGoldAndSilver } from 'hooks/goldandsilver/useEditGoldAndSilver'
import { useFetchGoldAndSilverById } from 'hooks/goldandsilver/useFetchGoldAndSilverById'
import FormikControl from 'components/FormikControl'
import { CCol, CButton } from '@coreui/react'
import Spinner from 'components/spinner/Spinner'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

const EditGoldAndSilver = () => {
  const { id } = useParams()
  const [initialValues, setInitialValues] = useState({
    id: 0,
    rate: '',
    date: '',
    gold_silver_type: '',
  })

  const { mutate: handleEdit } = useEditGoldAndSilver()

  const onSuccess = (data) => {
    setInitialValues({
      ...initialValues,
      id: data.payload.id,
      rate: data.payload.rate,
      date: data.payload.date,
      gold_silver_type: data.payload.gold_silver_type,
    })
  }

  const { isLoading } = useFetchGoldAndSilverById(id, { enabled: !!id, onSuccess })

  const handleFormSubmit = (data) => {
    let bodyFormData = new FormData()
    bodyFormData.append('id', data.id)
    bodyFormData.append('rate', data.rate)
    bodyFormData.append('date', data.date)
    bodyFormData.append('gold_silver_type', data.gold_silver_type)
    handleEdit(bodyFormData)
  }

  const validationSchema = Yup.object({
    rate: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    gold_silver_type: Yup.string().required('Required'),
  })

  if (isLoading) {
    return <Spinner />
  }

  // TODO: Store the advertisement errors into a state, then render the appropriate error message on the applicable input fields. The prop documentation of coreUI is given here: https://coreui.io/react/docs/4.0/forms/validation/. Follow it.
  return (
    <div>
      <div className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            handleFormSubmit(values)
          }}
        >
          <Form autoComplete="off" className="row g-3">
            <FormikControl
              control="input"
              type="text"
              placeholder="Rate"
              label="Rate"
              name="rate"
            />
            <FormikControl
              control="datepicker"
              type="text"
              placeholder="Date"
              label="Date"
              name="date"
            />
            <FormikControl
              control="radio"
              placeholder="Gold/Silver"
              type="tel"
              label="Gold/Silver"
              name="gold_silver_type"
              options={[
                { key: 'Gold', value: 1 },
                { key: 'Silver', value: 0 },
              ]}
            />
            <CCol xs={12}>
              <CButton type="submit" className="mt-2">
                Update Gold/Silver
              </CButton>
            </CCol>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default EditGoldAndSilver
