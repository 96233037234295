import React from 'react'
import Spinner from 'components/spinner/Spinner'
import { useFetchEventsById } from 'hooks/events/useFetchEventsById'
import { useParams } from 'react-router-dom'
import { CCol, CContainer, CFormLabel, CRow } from '@coreui/react'
import FormatDate from 'utils/format'

function EventsDetails() {
  const { id } = useParams()
  const { isLoading, data: events } = useFetchEventsById(id, { enabled: !!id })

  if (isLoading) {
    return <Spinner />
  }

  return (
    <CContainer className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
      <CRow>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Name:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{events.payload?.name}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Date of Birth:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{events.payload?.dob}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Type:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{events.payload?.type}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Created At:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{FormatDate(events.payload?.created_at)}</p>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default EventsDetails
