import React, { Suspense, lazy, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { differenceInMilliseconds } from 'date-fns'
import { AppSidebar, AppHeader, AppFooter } from 'components'
import { useLogout } from 'hooks/log/useLogout'
import constants from 'global/constants'
import token from 'utils/token'
import { toast } from 'react-toastify'

const Spinner = lazy(() => import('components/spinner/Spinner'))

const Layout = ({ children }) => {
  const { status: logoutStatus, mutate: logoutAdmin } = useLogout()

  /**
   * Token expiry date Timeout watcher
   * Immediately logout users when timemout ends
   *  */

  //for temporary solution for 5 minutes
  useEffect(() => {
    // const expiryDateInNumber = token.getTokenExpiryDate()

    let tokenTimeout

    // if (expiryDateInNumber) {
    //   const expiryDate = new Date(+expiryDateInNumber * 1000)
    //   const expiryDurationInMilliseconds = differenceInMilliseconds(expiryDate, new Date())

    tokenTimeout = setTimeout(() => {
      logoutAdmin()
      toast.warn('Session has expired.')
    }, 5000000)
    // }

    return () => {
      // if (tokenTimeout) {
      clearTimeout(tokenTimeout)
    }
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <AppSidebar items={constants.navbar} />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <Suspense fallback={<Spinner />}>
          <div className="body flex-grow-1 px-3">{children}</div>
        </Suspense>
        <AppFooter />
      </div>
    </div>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
