import { useMutation, useQueryClient } from 'react-query'

import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { toast } from 'react-toastify'

const deleteUser = (id) => {
  return http().delete(endpoints.user.userById.replace('{id}', id))
}

export function useDeleteUser() {
  const queryClient = useQueryClient()
  return useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('fetchUser')
      toast.success('User has been deleted successfully')
    },
    onError: (err) => {
      console.log(err)
      toast.error('Unfortunately, deletition of user was not successful.')
    },
  })
}
