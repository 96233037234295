import { endpoints } from 'global/endpoints'
import { useQuery } from 'react-query'
import http from 'utils/http'

const fetchEpaperById = (id) => {
  const updatedEndpoints = endpoints.epaper.epaperById.replace('{id}', id)
  return http().get(updatedEndpoints)
}

export function useFetchEpaperById(id, options) {
  return useQuery(['fetchEpaperById', id], () => fetchEpaperById(id), options)
}
