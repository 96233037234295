import React, { useState } from 'react'
import { useEditEvents } from 'hooks/events/useEditEvents'
import { useFetchEventsById } from 'hooks/events/useFetchEventsById'
import FormikControl from 'components/FormikControl'
import { CCol, CButton } from '@coreui/react'
import Spinner from 'components/spinner/Spinner'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

const EditEvents = () => {
  const { id } = useParams()
  const [initialValues, setInitialValues] = useState({
    id: 0,
    name: '',
    dob: '',
    type: '',
  })

  const { mutate: editFmAndTvs } = useEditEvents()

  const onSuccess = (data) => {
    setInitialValues({
      ...initialValues,
      id: data.payload.id,
      name: data.payload.name,
      dob: data.payload.dob,
      type: data.payload.type,
    })
  }

  const { isLoading } = useFetchEventsById(id, { enabled: !!id, onSuccess })

  const handleFormSubmit = (data) => {
    let bodyFormData = new FormData()
    bodyFormData.append('id', data.id)
    bodyFormData.append('name', data.name)
    bodyFormData.append('dob', data.dob)
    bodyFormData.append('type', data.type)
    editFmAndTvs(bodyFormData)
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    dob: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
  })

  if (isLoading) {
    return <Spinner />
  }

  // TODO: Store the advertisement errors into a state, then render the appropriate error message on the applicable input fields. The prop documentation of coreUI is given here: https://coreui.io/react/docs/4.0/forms/validation/. Follow it.
  return (
    <div>
      <div className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            handleFormSubmit(values)
          }}
        >
          <Form autoComplete="off" className="row g-3">
            <FormikControl
              control="input"
              type="text"
              placeholder="Name"
              label="Name"
              name="name"
            />
            <FormikControl
              control="input"
              type="text"
              placeholder="Date of birth"
              label="Date of birth"
              name="dob"
            />
            <FormikControl
              control="select"
              placeholder="Type"
              type="text"
              label="Type"
              name="type"
              options={[
                { key: 'Celebrity', value: 'celebrity' },
                { key: 'Social Worker', value: 'social_worker' },
              ]}
            />
            <CCol xs={12}>
              <CButton type="submit" className="mt-2">
                Update Events
              </CButton>
            </CCol>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default EditEvents
