import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { CFormSwitch } from '@coreui/react'

const SwitchCheckBox = ({ value, handleSwitch, id }) => {
  const [toggle, setToggle] = useState(value)
  const handleToggle = (e) => {
    handleSwitch(e.target.checked, id)
    setToggle(e.target.checked)
  }

  return <CFormSwitch checked={toggle} id="formSwitchCheckDefault" onChange={handleToggle} />
}

export default SwitchCheckBox

SwitchCheckBox.propTypes = {
  value: PropTypes.bool,
  id: PropTypes.number,
  handleSwitch: PropTypes.func,
}
