import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { routePaths } from 'global/routePaths'
import { endpoints } from 'global/endpoints'
import http from 'utils/http'
import { toast } from 'react-toastify'

const editEpaper = ({ ...postData }) => {
  if (postData.id) {
    const updatedEndpoints = endpoints.epaper.update.replace('{id}', postData.id)
    return http('multipart').put(updatedEndpoints, postData)
  }
}

export function useEditEpaper() {
  const history = useHistory()

  return useMutation(editEpaper, {
    onSuccess: () => {
      toast.success('Epaper has been edited successfully')
      history.push(routePaths.home.epaper.base)
    },
    onError: (err) => {
      console.log(err)
      toast.error('Unfortunately, update of Epaper was not successful.')
    },
  })
}
