import React from 'react'
import Spinner from 'components/spinner/Spinner'
import { useFetchFmAndTvsById } from 'hooks/fmandtvs/useFetchFmAndTvsById'
import { useParams } from 'react-router-dom'
import { CCol, CContainer, CFormLabel, CRow } from '@coreui/react'
import FormatDate from 'utils/format'

function FmAndTvsDetails() {
  const { id } = useParams()
  const { isLoading, data: user } = useFetchFmAndTvsById(id, { enabled: !!id })

  if (isLoading) {
    return <Spinner />
  }

  return (
    <CContainer className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
      <CRow>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Name:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{user.payload?.name}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Nepalese Name:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{user.payload?.name_np}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Code:</CFormLabel>
        <CCol sm={9} md={4}>
          <p>{user.payload?.code}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Type:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{user.payload?.type}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Created At:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center">
          <p>{FormatDate(user.payload?.created_at)}</p>
        </CCol>
        <CFormLabel className="col-sm-3 col-md-2 fw-semibold">Live Stream Link:</CFormLabel>
        <CCol sm={9} md={4} className="d-flex items-center text-break">
          <p>{user.payload?.live_stream_link}</p>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default FmAndTvsDetails
