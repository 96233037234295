import React, { useState } from 'react'
import { useCreateEpaper } from 'hooks/epaper/useCreateEpaper'
import { CCol, CButton, CImage } from '@coreui/react'
import FormikControl from 'components/FormikControl'
import { Form, Formik } from 'formik'
import image from 'assets/images/add.png'
import * as Yup from 'yup'

const AddEpaper = () => {
  const [initialValues, setInitialValues] = useState({
    title: '',
    title_np: '',
    imageUrl: '',
    link: '',
    type: '',
  })

  const { mutate: addEpaper } = useCreateEpaper()

  const handleFormSubmit = (data) => {
    let bodyFormData = new FormData()
    bodyFormData.append('title', data.title)
    bodyFormData.append('title_np', data.title_np)
    bodyFormData.append('imageUrl', data.imageUrl)
    bodyFormData.append('link', data.link)
    bodyFormData.append('type', data.type)
    addEpaper(bodyFormData)
  }

  const validationSchema = Yup.object({
    title: Yup.string().required('Required'),
    title_np: Yup.string().required('Required'),
    imageUrl: Yup.string().required('Required'),
    link: Yup.string().required('Required'),
  })

  // TODO: Store the advertisement errors into a state, then render the appropriate error message on the applicable input fields. The prop documentation of coreUI is given here: https://coreui.io/react/docs/4.0/forms/validation/. Follow it.
  return (
    <div>
      <div className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            handleFormSubmit(values)
          }}
        >
          {({ setFieldValue }) => (
            <Form autoComplete="off" className="row g-3">
              <CCol md={12} style={{ position: 'relative' }}>
                <CImage
                  rounded
                  thumbnail
                  width={120}
                  height={120}
                  src={
                    (initialValues.imageUrl && URL.createObjectURL(initialValues.imageUrl)) || image
                  }
                />
                <input
                  type="file"
                  placeholder="Image"
                  label="Image"
                  name="imageUrl"
                  style={{
                    opacity: 0,
                    left: 0,
                    position: 'absolute',
                    height: 120,
                    width: 120,
                    zIndex: 9999,
                  }}
                  onChange={(event) => {
                    setInitialValues({ ...initialValues, imageUrl: event.currentTarget.files[0] })
                    setFieldValue('imageUrl', event.currentTarget.files[0])
                  }}
                />
              </CCol>
              <FormikControl
                control="input"
                type="text"
                placeholder="Title"
                label="Title"
                name="title"
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Nepalese Title"
                label="Nepalese Title"
                name="title_np"
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Link"
                label="Link"
                name="link"
              />
              <FormikControl
                control="select"
                placeholder="Type"
                type="text"
                label="Type"
                name="type"
                options={[
                  { key: 'Celebrity', value: 'celebrity' },
                  { key: 'Poltics', value: 'poltics' },
                ]}
              />
              <CCol xs={12}>
                <CButton type="submit" className="mt-2">
                  Add Epaper
                </CButton>
              </CCol>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AddEpaper
