import { useHistory } from 'react-router'
import { useMutation, useQueryClient } from 'react-query'

import { routePaths } from 'global/routePaths'
import * as storage from 'utils/storage'
import http from 'utils/http'
import { endpoints } from 'global/endpoints'

const logoutAdmin = () => {
  const updatedEndpoints = endpoints.logout.base
  return http().post(updatedEndpoints)
}

export function useLogout() {
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutation(logoutAdmin, {
    onSuccess: () => {
      queryClient.clear()
      storage.clear_local()
      history.push(routePaths.auth.login)
    },
  })
}
