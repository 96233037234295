import React, { useState } from 'react'
import { useEditFmAndTvs } from 'hooks/fmandtvs/useEditFmAndTvs'
import { useFetchFmAndTvsById } from 'hooks/fmandtvs/useFetchFmAndTvsById'
import FormikControl from 'components/FormikControl'
import { CCol, CButton, CImage } from '@coreui/react'
import Spinner from 'components/spinner/Spinner'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import image from 'assets/images/add.png'
import * as Yup from 'yup'

const EditFmAndTvs = () => {
  const { id } = useParams()
  const [initialValues, setInitialValues] = useState({
    id: 0,
    name: '',
    name_np: '',
    image_id: '',
    live_stream_link: '',
    type: '',
  })

  const { mutate: editFmAndTvs } = useEditFmAndTvs()

  const onSuccess = (data) => {
    setInitialValues({
      ...initialValues,
      id: data.payload.id,
      name: data.payload.name,
      name_np: data.payload.name_np,
      type: data.payload.type,
      live_stream_link: data.payload.live_stream_link,
    })
  }

  const { isLoading } = useFetchFmAndTvsById(id, { enabled: !!id, onSuccess })

  const handleFormSubmit = (data) => {
    let bodyFormData = new FormData()
    bodyFormData.append('id', data.id)
    bodyFormData.append('name', data.name)
    bodyFormData.append('name_np', data.name_np)
    bodyFormData.append('image_id', data.image_id)
    bodyFormData.append('type', data.type)
    bodyFormData.append('live_stream_link', data.live_stream_link)
    editFmAndTvs(bodyFormData)
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    name_np: Yup.string().required('Required'),
    image_id: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    live_stream_link: Yup.string().required('Required'),
  })

  if (isLoading) {
    return <Spinner />
  }

  // TODO: Store the advertisement errors into a state, then render the appropriate error message on the applicable input fields. The prop documentation of coreUI is given here: https://coreui.io/react/docs/4.0/forms/validation/. Follow it.
  return (
    <div>
      <div className="body flex-grow-1 px-3 py-3 border mt-3 rounded">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            handleFormSubmit(values)
          }}
        >
          {({ setFieldValue }) => (
            <Form autoComplete="off" className="row g-3">
              <CCol md={12} style={{ position: 'relative' }}>
                <CImage
                  rounded
                  thumbnail
                  width={120}
                  height={120}
                  src={(initialValues.image_id && initialValues.image_id) || image}
                />
                <input
                  type="file"
                  placeholder="Image"
                  label="Image"
                  name="image_id"
                  style={{
                    opacity: 0,
                    left: 0,
                    position: 'absolute',
                    height: 120,
                    width: 120,
                    zIndex: 9999,
                  }}
                  onChange={(event) => {
                    setInitialValues({
                      ...initialValues,
                      image_id: URL.createObjectURL(event.currentTarget.files[0]),
                    })
                    setFieldValue('image_id', event.currentTarget.files[0])
                  }}
                />
              </CCol>
              <FormikControl
                control="input"
                type="text"
                placeholder="Name"
                label="Name"
                name="name"
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Nepalese Name"
                label="Nepalese Name"
                name="name_np"
              />
              <FormikControl
                control="input"
                placeholder="Live Stream Link"
                type="text"
                label="Live Stream Link"
                name="live_stream_link"
              />
              <FormikControl
                control="radio"
                placeholder="Type"
                type="text"
                label="Type"
                name="type"
                options={[
                  { key: 'Fm', value: 'fm' },
                  { key: 'Tvs', value: 'tv' },
                ]}
              />
              <CCol xs={12}>
                <CButton type="submit" className="mt-2">
                  Update Fm/Tvs
                </CButton>
              </CCol>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default EditFmAndTvs
