import React, { Component } from 'react'
import './scss/style.scss'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import AppRouter from 'containers/AppRouter'
import Spinner from './components/spinner/Spinner'
import { queryClient } from './utils/queryClient'

class App extends Component {
  render() {
    return (
      <>
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <React.Suspense fallback={<Spinner />}>
          <QueryClientProvider client={queryClient}>
            <AppRouter />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </React.Suspense>
      </>
    )
  }
}

export default App
