import { endpoints } from 'global/endpoints'
import { useQuery } from 'react-query'
import http from 'utils/http'

const fetchUserById = (id) => {
  const updatedEndpoints = endpoints.user.userById.replace('{id}', id)
  return http().get(updatedEndpoints)
}

export function useFetchUserById(id, options) {
  return useQuery(['fetchUserById', id], () => fetchUserById(id), options)
}
