import { endpoints } from 'global/endpoints'
import { useQuery } from 'react-query'
import http from 'utils/http'

const fetchFmAndTvsById = (id) => {
  const updatedEndpoints = endpoints.fmandtvs.fmandtvsById.replace('{id}', id)
  return http().get(updatedEndpoints)
}

export function useFetchFmAndTvsById(id, options) {
  return useQuery(['fetchFmAndTvsById', id], () => fetchFmAndTvsById(id), options)
}
